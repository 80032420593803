<template>
  <div class="menu-body"
    v-if="$oidc.isAuthenticated && $oidc.userProfile && $oidc.userProfile.roles && $oidc.userProfile.roles.length > 0">
    <Loader v-if="showLoader" />
    <Breadcrumb />
    <div class="outer-block">
      <div class="tab-titles">
        <div class="primary-color title-text"></div>

        <div class="button-dd">
          <div class="download-view action-btn" @click="downloadPdf">
            <img alt="download" src="../../../assets/download_icon.svg" />
          </div>
          <div class="funnel-con">
            <div class="action-btn primary-color" v-b-modal.action-filter>
              <b-icon icon="funnel"></b-icon>
            </div>
          </div>
          <b-modal id="action-filter" ref="my-modal" :hide-header="true" :hide-footer="true" dialog-class="right">
            <ActionItemFilter :hideModal="hideModal" :filterArray="filterArray" :applyFilter="applyFilter"
              :resetFilter="resetFilter" :changedFilter="() => { }" :filterSelected="filterSelected"
              :onSelect="onSelect" :onRemove="onRemove" />
          </b-modal>
        </div>
      </div>
      <div style="padding: 2rem">
        <b-table v-if="bottleneckPracticeItems && bottleneckPracticeItems.length > 0"
          style="max-height: 45rem !important" sticky-header id="bottleneckPractices" class="data-table gn-table"
          striped responsive hover :items="bottleneckPracticeItems" :fields="bottleneckPracticeFields">
          <template #cell(pillarName)="row">
            {{ row.item.pillarCode }}
          </template>
          <template #cell(practiceNo)="row">
            {{ row.item.practiceNo + "." + row.item.level }}
          </template>
        </b-table>
        <div class="no-data" v-if="
  !bottleneckPracticeItems || bottleneckPracticeItems.length === 0
        ">
          <img alt="no data" src="@/assets/No_Data.svg" />
          <span class="ed-date">Please modify filters</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumb from "../../../components/common/Breadcrumb.vue";
import Loader from "../../../components/common/Loader.vue";
import Api from "@/common/constants/api";
import ApiCalls from "@/common/api/apiCalls";
import ActionItemFilter from "@/components/Dashboard/ActionItemFilter.vue";
import Helper from "@/common/helper/index";
export default {
  name: "BottleneckPractice",
  data() {
    return {
      pillarDataList: [],
      regionList: [],
      pillarList: [],
      currentYear: new Date().getFullYear(),
      previousYear: new Date().getFullYear() - 1,
      showLoader: false,
      bottleneckPracticeItems: [],
      bottleneckPracticeFields: [
        {
          key: "pillarName",
          sortable: false,
          label: "Pillar",
        },
        {
          key: "practiceNo",
          sortable: false,
          label: "Practice No",
        },
        {
          key: "practiceDesc",
          sortable: false,
          label: "Practice Description",
        },
        {
          key: "level",
          sortable: false,
          label: "Level",
        },
      ],
    };
  },
  components: {
    Breadcrumb,
    Loader,
    ApiCalls,
    Api,
    ActionItemFilter,
  },
  beforeCreate() {
    this.$store.dispatch("selectedMenu", {
      main: 3,
      subMenu: 3,
    });
    this.$store.dispatch("breadcrumb", {
      title: "Bottleneck Practices",
      breadcrumb: [
        { name: "Home / ", primary: true, redirect: "/dashboard" },
        { name: "Reports / ", primary: true, redirect: "/" },
        { name: "Bottleneck Practices", primary: false },
      ],
    });
  },
  created() {
    this.resetFilter();
  },
  computed: {},
  methods: {
    getPlantList() {
      ApiCalls.httpCallDetails(Api.GETPLANTLIST, "get").then((dataJSON) => {
        let data = dataJSON.data;
        if (data) {
          this.regionList.push({
            value: "SelectAll",
            label: "All",
            checked: true,
          });
          for (const element of data) {
            this.regionList.push({
              value: element.plantId,
              label: element.plantName,
              checked: true,
            });
          }
          this.filterArray = [
            {
              labelName: "Plant Name",
              arrayValue: this.regionList,
              id: "PlantName",
              multiple: true,
            },
            {
              labelName: "Pillar Name",
              arrayValue: this.pillarList,
              id: "PillarName",
              multiple: true,
            },
          ];
          this.filterSelected = this.filterArray;          
        }
      });
    },
    getPillarList() {
      ApiCalls.httpCallDetails(Api.GETPILLARDROPDOWNLIST, "get").then(
        (dataJSON) => {
          this.pillarDataList = dataJSON.data;
          let data = dataJSON.data;
          if (data) {
            this.pillarList.push({
              value: "SelectAll",
              label: "All",
              checked: true,
            });
            for (const element of data) {
              this.pillarList.push({
                value: element.pillarId,
                label: element.pillarName,
                checked: true,
              });
            }
          }
          this.filterArray = [
            {
              labelName: "Plant Name",
              arrayValue: this.regionList,
              id: "PlantName",
              multiple: true,
            },
            {
              labelName: "Pillar Name",
              arrayValue: this.pillarList,
              id: "PillarName",
              multiple: true,
            },
          ];
          this.filterSelected = this.filterArray;          
        }
      );
    },
    getPracticeData(req) {
      ApiCalls.httpCallDetails(Api.GETBOTTLEPRACTICEDATA, "post", req).then(
        (data) => {
          let arr = [];

          this.bottleneckPracticeItems = [];
          data.data.plants.forEach((plant) => {
            if (plant.pillers && plant.pillers.length > 0) {
              plant.pillers.forEach((piller) => {
                for (let i = 0; i < piller.level_id.length; i++) {
                  let indexPillar = this.pillarDataList.findIndex(
                    (val) =>
                      val.pillarName.toString().toUpperCase() ===
                      piller.piller_Name.toString().toUpperCase()
                  );
                  let obj = {
                    pillarName: piller.piller_Name,
                    practiceNo: piller.practice_Number[i],
                    practiceDesc: piller.practiceDescription[i],
                    level: piller.level_id[i],
                    pillarCode:
                      indexPillar !== -1
                        ? this.pillarDataList[indexPillar].pillarCode
                        : "",
                  };
                  arr.push(obj);
                  this.bottleneckPracticeItems.push(obj);
                }
              });
            }
          });

          // setTimeout(() => {

          // }, 300);
        }
      );
    },

    downloadPdf() {
      this.download = true;
      Helper.export_table_to_excel("bottleneckPractices", "Bottleneck Practices");
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    applyFilter() {
      let filterSelected = this.filterSelected;      
      this.showLoader = true;
      let plantIds = [];
      let pillarIds = [];
      for (let i = 0; i < this.filterSelected.length; i++) {
        for (const element of this.filterSelected[i].arrayValue) {          
          if (
            i === 0 &&
            element.checked === true &&
            element.value !== "SelectAll"
          ) {
            plantIds.push("" + element.value);            
          } else if (
            i === 1 &&
            element.checked === true &&
            element.value !== "SelectAll"
          ) {
            pillarIds.push("" + element.value);            
          }
        }
      }

      // setTimeout(() => {
      console.log(filterSelected);
      let req = {
        plantIDs: plantIds,
        pillarIDs: pillarIds,
      };

      this.showLoader = false;
      this.hideModal();
      this.getPracticeData(req);
      // }, 0);
    },
    resetFilter() {
      this.getPlantList();
      this.getPillarList();      
      this.filterArray = [
        {
          labelName: "Plant Name",
          arrayValue: this.regionList,
          id: "PlantName",
          multiple: true,
        },
        {
          labelName: "Pillar Name",
          arrayValue: this.pillarList,
          id: "PillarName",
          multiple: true,
        },
      ];
      this.filterSelected = this.filterArray;
      // setTimeout(() => {
      let plantIds = [];
      let pillarIds = [];
      for (let i = 0; i < this.filterSelected.length; i++) {
        for (const element of this.filterSelected[i].arrayValue) {
          if (
            i === 0 &&
            element.checked === true
          ) {
            plantIds.push(element.value);
          } else if (
            i === 1 &&
            element.checked === true
          ) {
            pillarIds.push(element.value);
          }
        }
      }
      let req = {
        plantIDs: plantIds,
        pillarIDs: pillarIds,
      };
      this.hideModal();
      this.getPracticeData(req);
      // }, 0);
    },
    onSelect(option, index) {      
      if (option.value === "SelectAll") {
        for (const element of this.filterSelected[index].arrayValue) {
          element.checked =
            !element.checked;
        }
      } else {
        let indexLocal = this.filterSelected[index].arrayValue.findIndex(
          (item) => item.label == option.label
        );
        this.filterSelected[index].arrayValue[indexLocal].checked =
          !this.filterSelected[index].arrayValue[indexLocal].checked;
      }

      let countAll = 0;
      let countUn = 0;
      let j = 0;
      for (j = 0; j < this.filterSelected[index].arrayValue.length; j++) {
        if (this.filterSelected[index].arrayValue[j].checked === true) {
          countAll++;
        }
        if (this.filterSelected[index].arrayValue[j].checked === false) {
          countUn++;
        }
        if (j === this.filterSelected[index].arrayValue.length - 1) {
          if (countAll === this.filterSelected[index].arrayValue.length - 1) {
            this.filterSelected[index].arrayValue[0].checked = true;
          }
          if (countUn === this.filterSelected[index].arrayValue.length - 1) {
            this.filterSelected[index].arrayValue[0].checked = false;
          }
        }
      }
    },

    onRemove(option, index) {      
      if (option.value === "SelectAll") {
        for (const element of this.filterSelected[index].arrayValue) {
          element.checked =
            !element.checked;
        }
      } else {
        let indexL = this.filterSelected[index].arrayValue.findIndex(
          (item) => item.label == option.label
        );
        this.filterSelected[index].arrayValue[indexL].checked =
          !this.filterSelected[index].arrayValue[indexL].checked;
      }
      let countAll = 0;
      let countUn = 0;
      let j = 0;
      for (j = 0; j < this.filterSelected[index].arrayValue.length; j++) {
        if (this.filterSelected[index].arrayValue[j].checked === true) {
          countAll++;
        }
        if (this.filterSelected[index].arrayValue[j].checked === false) {
          countUn++;
        }
        if (j === this.filterSelected[index].arrayValue.length - 1) {
          if (countAll === this.filterSelected[index].arrayValue.length - 1) {
            this.filterSelected[index].arrayValue[0].checked = true;
          }
          if (countUn === this.filterSelected[index].arrayValue.length - 1) {
            this.filterSelected[index].arrayValue[0].checked = false;
          }
        }
      }
    },
  },
};
</script>
<style scoped>
#chart {
  padding: 0 2rem 2rem 0;
}

.outer-block {
  background-color: #fff;
  border-radius: 6px;
}

.tab-titles {
  padding: 2rem 2rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tab-options {
  margin-right: 1rem;
  display: flex;
}

.tab-option-text {
  font: 1.16rem "BarlowR", sans-serif;
  cursor: pointer;
}

.in-action {
  margin-right: 1.688em;
}

.tab-active-option {
  font: 1.18rem "BarlowM", sans-serif;
}

.active-tab-scroll-hr {
  border-top: 3px #274ba7 solid;
  width: 20%;
  margin-left: auto;
  margin-right: auto;
  opacity: 1 !important;
  margin-top: 1rem;
}

.select-dd {
  width: 10%;
}

.button-dd {
  display: inline-flex;
}

.funnel-con {
  /* border-radius: 2rem;
  border: 1px solid #274ba7;
  margin-left: 1rem;
  font-size: 1.33rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem; */
  font-size: 1.33rem;
}

.commited {
  background-color: #004ea8;
  width: 2rem;
  height: 2rem;
  border-radius: 50px;
}

.on-track {
  background-color: #109b10;
  width: 2rem;
  height: 2rem;
  border-radius: 50px;
}

.off-track {
  background-color: #d35757;
  width: 2rem;
  height: 2rem;
  border-radius: 50px;
}

.prior-years-actual {
  background-color: #274ba7;
  width: 2rem;
  height: 2rem;
  border-radius: 50px;
}

.sample-span {
  font: 1.33rem "BarlowR", sans-serif;
  margin: 0 2rem 0 0.7rem;
}

.sample-list {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2rem;
}

.title-text {
  font: 1.33rem "BarlowSb", sans-serif;
  padding: 0 0 2rem 2rem;
}

.data-table tbody {
  font-size: 0.875em;
}

#reports-gc-data-table th div {
  color: #313131;
}

#reports-gc-data-table tbody tr:nth-child(odd) {
  background-color: #fafafa !important;
}

#reports-gc-data-table tbody tr:nth-child(even):hover {
  background-color: #fff !important;
}

#reports-gc-data-table tr td:first-child {
  padding-left: 1.25em !important;
}

#reports-gc-data-table tr th:first-child {
  padding-left: 1.25em !important;
}

.download-view {
  border-radius: 2rem;
  border: 1px solid #274ba7;
  margin-left: 1rem;
  font-size: 1.33rem;
  margin-right: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.download-view:hover {
  background: #e3e8f7;
}

.action-btn {
  border: 1px #274ba7 solid;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 32px;
  cursor: pointer;
}

#bottleneckPractices>thead>tr>th:nth-child(2) {
  width: 4rem;
}
</style>