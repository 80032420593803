import { render, staticRenderFns } from "./BottleneckPractice.vue?vue&type=template&id=71c3fd62&scoped=true"
import script from "./BottleneckPractice.vue?vue&type=script&lang=js"
export * from "./BottleneckPractice.vue?vue&type=script&lang=js"
import style0 from "./BottleneckPractice.vue?vue&type=style&index=0&id=71c3fd62&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71c3fd62",
  null
  
)

export default component.exports